// src/App.js
import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>UpSkillMyPractice</h1>
        <p>Coming Soon</p>
      </header>
      <footer className="App-footer">
        <a href="https://legalutopia.co.uk" target="_blank" rel="noopener noreferrer">
          UK Website - UpskillMyPractice
        </a>
      </footer>
    </div>
  );
}

export default App;
